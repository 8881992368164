<template>
  <div>
    <van-popup v-model="show" @closed="closed" round :style="{ minWidth: '80%' }">
      <div style="background-color: white;  display: flex;flex-direction: column;margin-bottom: 0.5rem;">
        <div style="width: 100%; text-align: center;text-align: center; margin-top: 0.5rem;">
          <p style="font-weight: 600; color: black;font-size: 1rem;">绑定到您的十节优享+账户</p>
        </div>
        <div class="goods"
          :style="{ backgroundImage: 'url(' + getback(cardDate?.themeImg) + ')', margin: '0.5rem 1rem', with: '100%', height: '9.5rem', borderRadius: '0.5rem', backgroundSize: '100% 100%' }">
          <div class="flex" style="flex-direction: column;justify-content: space-between; height: 100%;">
            <div style="display: flex;justify-content: space-between;width: 100%;">
              <div class="cardNo">
                卡名称：{{ getCardName(cardDate.cardShowName?cardDate.cardShowName:cardDate.cardName) }}
              </div>
            </div>
            <div class="width-100 flex jcsb" style="margin-bottom: 1rem;">
              <div style="font-size: 0.85rem;color: #fdfafa;margin-left: 1rem;">
                <p style="color: white; margin-bottom: 0.2rem; ">
                  卡号：{{ cardDate?.cardNo }}
                </p>
                <div style=" font-size: 0.7rem;color: #fdfafa;">
                  {{ $moment(cardDate?.expireDate).format("YYYY.MM.DD") }} 到期
                </div>
              </div>
              <div v-if="ticketType == 'E3'"
                style="text-align: end; color: #ffffff;margin-right: 1rem;font-size: 0.85rem;">
                {{ cardDate?.cardRemainingPoint }}票
              </div>
              <div v-else
                style="text-align: end; color: #ffffff;margin-right: 1rem;font-size: 0.85rem;background-color: ;">
                {{ cardDate?.cardRemainingPoint }}点
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="bottomlayout">
        <div class="bottombt1" @click="closed"> 再想想</div>
        <div class="bottombt2" @click="sure"> 确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    cardDate: null
  },
  data() {
    return {
      imageback: require('@/assets/images/card_bg.svg'),
      show: true,
      ticketType: ''
    };
  },
  components: {},
  methods: {
    sure() {
      this.$emit("sured");
    },
    getCardName(str){
      return str.length > 10 ? str.substring(0, 10) : str
    },
    getback(str) {
      if (str == '' || str == undefined) {
        return this.imageback
      } else {
        return str
      }
    },
    closed() {
      this.$emit("closed", false);
    },
  },
  mounted() {
    // console.log(this.good);
    // 进入页面显示在顶部
    window.scroll(0, 0);
    this.ticketType = localStorage.getItem('entrCode')
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.img {
  width: 5rem;
}

.goods {}

.bottomlayout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.bottombt1 {
  width: 8rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid #666;
  border-radius: 1.5rem;
  text-align: center;
}

.bottombt2 {
  width: 8rem;
  height: 2.5rem;
  line-height: 2.5rem;
  background-color: #D59A54;
  border-radius: 1.5rem;
  color: white;
  text-align: center;
}

.tag {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  border: 1px solid #666;
  color: #666;
  margin: 0.2rem 1rem;
}

.cardNo {
  color: #fff;
  margin: 1rem 0 0 1rem;
  font-size: 0.85rem;
}

.selectTag {
  border-radius: 0.5rem;
  padding: 0.1rem 0.5rem;
  margin: 0.2rem 1rem;
  border: 1px solid #D33B2E;
  color: #D33B2E;
}

.btn {
  height: 2.5rem;
  margin-top: 0.5rem;
}
</style>